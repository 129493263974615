import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout.jsx"
import Hero from "../components/Hero.jsx"
import Seo from "../components/Seo"
import ArrowRight from "../assets/images/icons/arrow-right.png"
import StockImage from "../assets/images/stocks.png"
import IndexImage from "../assets/images/index.png"
import CurrencyImage from "../assets/images/currency.png"
import CryptoImage from "../assets/images/cripto.png"
import CommoditiesImage from "../assets/images/commodities.png"
import BonusImage from "../assets/images/bonus.png"
import ETFImage from "../assets/images/etf.png"
import IconScroll from "../assets/images/icons/scroll-top-icon.svg"

import "../styles/pages/Market.scss"

export default function Platform() {
  const intl = useIntl()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleScroll = id => {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "market_title" })} />
      <Hero title={intl.formatMessage({ id: "market_title" })} text="" />
      <section className="market">
        <div className="market__shortcut">
          <div className="market__shortcut-container">
            <button
              onClick={() => handleScroll("stocks")}
              className="market__shortcut-item market__shortcut-item--stocks"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_stock" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
            <button
              onClick={() => handleScroll("index")}
              className="market__shortcut-item market__shortcut-item--indexes"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_indices" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
            <button
              onClick={() => handleScroll("currency")}
              className="market__shortcut-item market__shortcut-item--currency"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_fx" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
            <button
              onClick={() => handleScroll("crypto")}
              className="market__shortcut-item market__shortcut-item--cripto"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_crypto" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
            <button
              onClick={() => handleScroll("commodities")}
              className="market__shortcut-item market__shortcut-item--commodities"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_commodities" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
            <button
              onClick={() => handleScroll("bonus")}
              className="market__shortcut-item market__shortcut-item--bonus"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_bonds" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
            <button
              onClick={() => handleScroll("etf")}
              className="market__shortcut-item market__shortcut-item--etf"
            >
              <p className="market__shortcut-text">
                {intl.formatMessage({ id: "market_item_etf" })}
              </p>
              <img
                src={ArrowRight}
                alt="Arrow"
                className="market__shortcut-icon"
              />
            </button>
          </div>
        </div>
        <div className="market-data" id="stocks">
          <div className="market-data__container">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_stock_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_stock_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_stock_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={StockImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
        <div className="market-data" id="index">
          <div className="market-data__container">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_indices_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_indices_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_indices_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={IndexImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
        <div className="market-data" id="currency">
          <div className="market-data__container">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_fx_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_fx_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_fx_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={CurrencyImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
        <div className="market-data" id="crypto">
          <div className="market-data__container">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_crypto_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_crypto_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_crypto_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={CryptoImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
        <div className="market-data" id="commodities">
          <div className="market-data__container">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_commodities_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_commodities_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_commodities_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={CommoditiesImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
        <div className="market-data" id="bonus">
          <div className="market-data__container">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_bonds_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_bonds_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_bonds_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={BonusImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
        <div className="market-data" id="etf">
          <div className="market-data__container market-data__container--last">
            <div className="market-data__box market-data__box--text">
              <h2 className="market-data__title">
                {intl.formatMessage({ id: "market_etf_title" })}
              </h2>
              <h3 className="market-data__subtitle">
                {intl.formatMessage({ id: "market_etf_text_one" })}
              </h3>
              <p className="market-data__text">
                {intl.formatMessage({ id: "market_etf_text_two" })}
              </p>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="market-data__button"
              >
                {intl.formatMessage({ id: "button_start" })}
                <img
                  src={ArrowRight}
                  alt="Arrow"
                  className="market-data__arrow"
                />
              </a>
            </div>
            <div className="market-data__box">
              <img
                src={ETFImage}
                alt="market-data"
                className="market-data__image"
              />
            </div>
          </div>
        </div>
      </section>
      <button id="scroll-btn" className="up__button" title="Scroll to top">
        <img src={IconScroll} alt="Up" className="up__image" />
      </button>
    </Layout>
  )
}
