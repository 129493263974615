import React from "react"
import "../styles/components/Hero.scss"

export default function Hero(props) {
  return (
    <section className="hero-comp">
      <div className="hero-comp__container">
        <h2 className="hero-comp__title">{props.title}</h2>
        <p className="hero-comp__text">{props.text}</p>
      </div>
    </section>
  )
}
